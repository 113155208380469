import React, { useState } from 'react';
import Modal from 'react-modal';
import packageInfo from '../../package.json'; // Adjust the path as needed

Modal.setAppElement('#root');

const ImageGenerator = ({ playlistId }) => {
    const [imageData, setImageData] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const [modalIsOpen, setIsOpen] = useState(false);

    const handleImageTransformation = () => {
        setIsLoading(true);
        fetch(`${packageInfo.proxy}/generate_playlist_image/${playlistId}`, {
            method: 'GET',
            mode: 'cors',
            credentials: 'include',
        })
            .then(response => response.json())
            .then(data => {
                setIsLoading(false);
                if (data && data.image_url) {
                    setImageData(data);
                    setIsOpen(true);
                } else {
                    throw new Error('Image URL not found in response');
                }
            })
            .catch(error => {
                console.error('Error generating playlist image:', error);
                setIsLoading(false);
            });
    };

    const closeModal = () => setIsOpen(false);

    return (
        <div className="image-generator-container">
            <div className="image-generator-box">
                <p>Transform your playlist to an image</p>
                <button onClick={handleImageTransformation} disabled={isLoading}>
                    {isLoading ? (
                        <>
                            <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                            {' Loading...'}
                        </>
                    ) : (
                        'Try me!'
                    )}
                </button>
            </div>
            <Modal
                isOpen={modalIsOpen}
                onRequestClose={closeModal}
                style={{
                    content: {
                        top: '50%',
                        left: '50%',
                        right: 'auto',
                        bottom: 'auto',
                        marginRight: '-50%',
                        transform: 'translate(-50%, -50%)',
                        width: '90%', // Adjusted for responsiveness
                        maxWidth: '600px', // Optional: limits the modal width
                        maxHeight: '90vh', // Ensures content does not exceed the viewport height
                        padding: '20px',
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        boxSizing: 'border-box',
                        overflow: 'hidden', // Prevents unnecessary scrolling
                    },
                    overlay: {
                        zIndex: 1000, // Ensures the modal is above other content
                    },
                }}
                contentLabel="Generated Image"
            >
                {imageData ? (
                    <div
                        style={{
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                            width: '100%',
                            height: '100%',
                        }}
                    >
                        <img
                            key={imageData.image_url}
                            src={imageData.image_url}
                            alt="Generated Playlist"
                            style={{
                                maxWidth: '100%',
                                maxHeight: 'calc(100% - 60px)', // Leaves space for buttons
                                objectFit: 'contain',
                            }}
                        />
                        <div
                            style={{
                                display: 'flex',
                                justifyContent: 'space-around',
                                width: '100%',
                                marginTop: '10px',
                            }}
                        >
                            <button
                                onClick={() => {
                                    const link = document.createElement('a');
                                    link.href = imageData.image_url;
                                    link.download = 'playlist_image.png';
                                    document.body.appendChild(link);
                                    link.click();
                                    document.body.removeChild(link);
                                }}
                            >
                                Save Image
                            </button>
                            <button onClick={closeModal} style={{ marginLeft: '10px' }}>
                                Close
                            </button>
                        </div>
                    </div>
                ) : (
                    <p>Image is not available. Please try again.</p>
                )}
            </Modal>
        </div>
    );
};

export default ImageGenerator;
