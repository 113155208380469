// components/RadarChart.js
import React from 'react';
import ReactApexChart from 'react-apexcharts';

const RadarChart = ({ options, series }) => {
    return (
        <div className="radar-chart-container">
            <ReactApexChart options={options} series={series} type="radar" height={350} />
        </div>
    );
};

export default RadarChart;