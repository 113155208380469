import React, { useState, useMemo } from 'react';

const TrackListTable = ({ tracks }) => {
    const [sortConfig, setSortConfig] = useState({ key: '', direction: '' });

    const convertDurationToSeconds = (duration) => {
        const [minutes, seconds] = duration.split(':').map(Number);
        return minutes * 60 + seconds;
    };

    // Add an index property to each track for original ordering
    const indexedTracks = useMemo(() => tracks.map((t, index) => ({ ...t, index })), [tracks]);

    const sortedTracks = useMemo(() => {
        if (!sortConfig.key) return indexedTracks;

        const sortableTracks = [...indexedTracks];
        sortableTracks.sort((a, b) => {
            let aValue = a[sortConfig.key];
            let bValue = b[sortConfig.key];

            if (sortConfig.key === 'duration_min_sec') {
                aValue = convertDurationToSeconds(aValue);
                bValue = convertDurationToSeconds(bValue);
            } else if (sortConfig.key === 'index') {
                // Original order sorting
                aValue = a.index;
                bValue = b.index;
            }

            if (aValue < bValue) {
                return sortConfig.direction === 'ascending' ? -1 : 1;
            }
            if (aValue > bValue) {
                return sortConfig.direction === 'ascending' ? 1 : -1;
            }
            return 0;
        });

        return sortableTracks;
    }, [indexedTracks, sortConfig]);

    const requestSort = (key) => {
        if (key === 'index' && !sortConfig.key) {
            // Clicking the row number without any other sort applies an ascending sort by default
            setSortConfig({ key, direction: 'ascending' });
        } else {
            let direction = 'ascending';
            if (sortConfig.key === key && sortConfig.direction === 'ascending') {
                direction = 'descending';
            }
            setSortConfig({ key, direction });
        }
    };

    if (!Array.isArray(tracks) || tracks.length === 0) {
        return <div>No track data available.</div>;
    }

    return (
        <table className="modern-table">
            <thead>
                <tr>
                    <th onClick={() => requestSort('index')}>#</th>
                    <th onClick={() => requestSort('name')}>Track</th>
                    <th onClick={() => requestSort('duration_min_sec')}>Duration (min:sec)</th>
                    <th onClick={() => requestSort('artist')}>Artist</th>
                    <th onClick={() => requestSort('album')}>Album</th>
                </tr>
            </thead>
            <tbody>
                {sortedTracks.map((track, index) => (
                    <tr key={track.index}>
                        <td>{index + 1}</td>
                        <td>{track.name}</td>
                        <td>{track.duration_min_sec}</td>
                        <td>{track.artist}</td>
                        <td>{track.album}</td>
                    </tr>
                ))}
            </tbody>
        </table>
    );
};

export default TrackListTable;
