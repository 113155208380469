import React, { useState, useEffect } from 'react';


function Test() {
    const [data, setData] = useState([]);

    useEffect(() => {
        fetch("/members")
            .then(res => {
                if (!res.ok) {
                    throw new Error(`HTTP error! status: ${res.status}`);
                }
                return res.json();
            })
            .then(data => {
                setData(data);
                console.log(data);
            })
            .catch(e => {
                console.log(e);
                // Handle the error state appropriately
            });
    }, []);

    return (
        <div>
            {data.length === 0 ? (
                <p>Loading...</p>
            ) : (
                data.map((member, i) => (
                    <p key={i}>{member}</p> // Assuming `member` has a property `name`
                ))
            )}
        </div>
    );
}

export default Test;

