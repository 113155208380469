// src/utils/transformData.js
const colors = ['#008FFB', '#00E396', '#FEB019', '#FF4560', '#775DD0', '#00D9E9', '#FF66C3'];

export const transformData = (detailedDistribution) => {
  const yearlyData = [];
  const detailedData = {};

  Object.keys(detailedDistribution).forEach((year, index) => {
    const months = detailedDistribution[year];
    const totalSongs = Object.values(months).reduce((sum, count) => sum + count, 0);

    yearlyData.push({
      x: year,
      y: totalSongs,
      color: colors[index % colors.length],
      quarters: Object.entries(months).map(([month, count]) => ({
        x: month,
        y: count,
      })),
    });

    Object.entries(months).forEach(([month, count]) => {
      if (!detailedData[month]) {
        detailedData[month] = {};
      }
      if (!detailedData[month][year]) {
        detailedData[month][year] = 0;
      }
      detailedData[month][year] += count;
    });
  });

  return { yearlyData, detailedData };
};
