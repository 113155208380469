// hooks/usePlaylistsData.js
import { useState, useEffect } from 'react';
import packageInfo from '../../package.json'; // Adjust the path as needed

const usePlaylistsData = () => {
    const [playlists, setPlaylists] = useState([]); 
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState('');

    useEffect(() => {
        const storedPlaylists = localStorage.getItem('playlists');
        if (storedPlaylists) {
            setPlaylists(JSON.parse(storedPlaylists));
        } else {
            fetchPlaylists(); // Automatically fetch if not available in localStorage
        }
    }, []);

    const fetchPlaylists = () => {
        setError('');
        setIsLoading(true);
        fetch(`${packageInfo.proxy}/get_user_playlists`, {
            method: 'GET',
            mode: 'cors',
            credentials: 'include',
        })
            .then(response => {
                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }
                return response.json();
            })
            .then(data => {
                setPlaylists(data);
                localStorage.setItem('playlists', JSON.stringify(data));
                setIsLoading(false);
            })
            .catch(error => {
                console.error('Error fetching playlists:', error);
                setError('Failed to fetch playlists.');
                setIsLoading(false);
            });
    };

    return { playlists, isLoading, error, fetchPlaylists };
};

export default usePlaylistsData;