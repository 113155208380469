// FetchPlaylists.js
import React from 'react';
import { useNavigate } from 'react-router-dom';
import './PlaylistStyles.css'; // Import the CSS stylesheet
import usePlaylistsData from '../hooks/usePlaylistsData'; // Import the custom hook


function FetchPlaylists() {
    const navigate = useNavigate(); // Hook for navigation    
    const { playlists, isLoading, error, fetchPlaylists } = usePlaylistsData();

    const handlePlaylistClick = (playlistId) => {
        navigate(`/playlist/${playlistId}`);
    };

    return (
        <div className="playlist-container">
            <div className="fetch-section">
                <button onClick={fetchPlaylists}>Fetch Playlists</button>
                {isLoading && <p>Loading...</p>}
                {error && <p className="error-message">Error: {error}</p>}
            </div>
            <h3 className="playlist-title">Spotify Playlists</h3>
            {playlists && (
                <table className="modern-table">
                    <thead>
                        <tr>
                            <th>#</th>
                            <th>Playlist Name</th>
                        </tr>
                    </thead>
                    <tbody>
                        {playlists.map((playlist, index) => (
                            <tr key={playlist.id} onClick={() => handlePlaylistClick(playlist.id)}>
                                <td>{index + 1}</td>
                                <td>{playlist.name}</td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            )}
        </div>
    );
}

export default FetchPlaylists;
