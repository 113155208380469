// hooks/useChartData.js
import { useMemo } from 'react';

const useChartData = (playlistMetrics) => {
    const radarChartData = useMemo(() => {
        if (!playlistMetrics?.metrics?.audio_features_avg) {
            return { options: {}, series: [] };
        }

        const audioFeaturesAvg = playlistMetrics.metrics.audio_features_avg;
        const options = {

            // Radar Chart
                chart: {
                    height: 350,
                    type: 'radar',
                    toolbar: {
                        show: false // Disable the toolbar
                    }
                },
                responsive: [{
                    breakpoint: 480, // Adjust breakpoint as needed
                    options: {
                        chart: {
                            width: '100%', // Ensures chart is 100% width below this breakpoint
                            height: 300 // You may want to adjust height as well
                        },
                        legend: {
                            position: 'bottom', // Moves the legend to the bottom on small screens
                        }
                    }
                }],
                dataLabels: {
                    enabled: false
                },
                plotOptions: {
                    radar: {
                        size: 140,
                        polygons: {
                            strokeColors: '#e9e9e9',
                            fill: {
                                colors: ['#f8f8f8', '#fff']
                            }
                        }
                    }
                },
                title: {
                    text: 'Playlist Profile',
                    align: 'left', // Align the title to the left
                    offsetX: 30, // Shift the title a bit to the right to add padding
                    offsetY: -3,
                    style: {
                        fontSize: '22px',
                        color: '#333',
                        cssClass: 'apexcharts-title-text'
                    }
                },
                colors: ['#FF4560'],
                markers: {
                    size: 4,
                    colors: ['#fff'],
                    strokeColor: '#FF4560',
                    strokeWidth: 2,
                },
                tooltip: {
                    y: {
                        formatter: function (val) {
                            return val.toFixed(2); // Format the floating point numbers to two decimal places
                        }
                    }
                },
                xaxis: {
                    categories: ['Instrumentalness', 'Acousticness', 'Danceability', 'Energy', 'Liveness', 'Speechiness']
                },
                yaxis: {
                    tickAmount: 2, // Adjust the number of ticks as needed
                    min: 0, // Start y-axis from 0
                    max: 1, // Assuming the metrics values are between 0 and 1
                    labels: {
                        formatter: function (val) {
                            return val.toFixed(2); // Format the floating point numbers to two decimal places
                        }
                    }
                }

            // End of Radar Chart
        };
        const series = [{
            name: 'Average',
            data: [
                audioFeaturesAvg.instrumentalness,
                audioFeaturesAvg.acousticness,
                audioFeaturesAvg.danceability,
                audioFeaturesAvg.energy,
                audioFeaturesAvg.liveness,
                audioFeaturesAvg.speechiness,
            ],
        }];

        return { options, series };
    }, [playlistMetrics]);

    const treemapChartData = useMemo(() => {
        if (!playlistMetrics?.metrics?.['pop-sort_artists']) {
            return { options: {}, series: [] };
        }

        const artistData = playlistMetrics.metrics['pop-sort_artists'];
        const maxTracks = Math.max(...Object.values(artistData));
        const options = {
            // ... Your existing treemapOptions here
            chart: {
                type: 'treemap'
            },
            title: {
                text: 'Artist Distribution in Playlist'
            },
            tooltip: {
                y: {
                    formatter: function (val) {
                        return `${val} tracks`;
                    }
                }
            },
            plotOptions: {
                treemap: {
                    distributed: true, // Ensure it's distributed
                    enableShades: true // Disable default shading
                }
            },
            // This is optional since colors are dynamically set in series data
        
        };
        const series = [{
            data: Object.entries(artistData).map(([name, count]) => ({
                x: name,
                y: count,
                color: `hsl(240, ${100 - Math.round((count / maxTracks) * 100)}%, 50%)`
            }))
        }];

        return { options, series };
    }, [playlistMetrics]);

    return {
        radarChartData,
        treemapChartData
    };
};

export default useChartData;