// src/components/PlaylistTimeChart.js
import React, { useState, useEffect } from 'react';
import Chart from 'react-apexcharts';
import usePlaylistData from '../hooks/usePlaylistData';
import { transformData } from '../utils/transformData';
import './PlaylistTimeChartStyling.css';

const colors = ['#008FFB', '#00E396', '#FEB019', '#FF4560', '#775DD0', '#00D9E9', '#FF66C3'];

const PlaylistTimeChart = ({ playlistId }) => {
  const { data, isLoading, error } = usePlaylistData(playlistId);
  const [yearData, setYearData] = useState([]);
  const [quarterData, setQuarterData] = useState([]);
  const [detailedData, setDetailedData] = useState({}); // Define detailedData state

  useEffect(() => {
    if (data) {
      const { yearlyData, detailedData } = transformData(data.metrics.detailed_distribution);
      setYearData(yearlyData);
      setQuarterData([]);
      setDetailedData(detailedData); // Set detailedData state
    }
  }, [data]);

  const updateQuarterChart = (selectedPoints) => {
    if (selectedPoints.length > 0) {
      const series = selectedPoints.map(index => {
        const year = yearData[index].x;
        const data = Object.entries(detailedData)
          .map(([month, values]) => ({
            x: month,
            y: values[year] || 0,
          }))
          .sort((a, b) => a.x.localeCompare(b.x)); // Sort by month

        return {
          name: year,
          data: data,
          color: yearData[index].color
        };
      });

      setQuarterData(series);
    } else {
      setQuarterData([]);
    }
  };

  const handleDataPointSelection = (event, chartContext, config) => {
    const selectedPoints = config.selectedDataPoints[0];
    updateQuarterChart(selectedPoints);
  };

  const yearChartOptions = {
    chart: {
      id: 'barYear',
      type: 'bar',
      height: 400,
      events: {
        dataPointSelection: handleDataPointSelection,
      },
    },
    plotOptions: {
      bar: {
        distributed: true,
        horizontal: true,
        barHeight: '75%',
        dataLabels: {
          position: 'bottom',
        },
      },
    },
    dataLabels: {
      enabled: true,
      textAnchor: 'start',
      style: {
        colors: ['#fff'],
      },
      formatter: (val, opt) => opt.w.globals.labels[opt.dataPointIndex],
      offsetX: 0,
      dropShadow: {
        enabled: true,
      },
    },
    colors: colors,
    states: {
      normal: {
        filter: {
          type: 'desaturate',
        },
      },
      active: {
        allowMultipleDataPointsSelection: true,
        filter: {
          type: 'darken',
          value: 1,
        },
      },
    },
    tooltip: {
      x: {
        show: false,
      },
      y: {
        title: {
          formatter: (val, opts) => opts.w.globals.labels[opts.dataPointIndex],
        },
      },
    },
    title: {
      text: 'Number of Songs Released Per Year',
      offsetX: 15,
    },
    subtitle: {
      text: '(Click on bar to see details)',
      offsetX: 15,
    },
    yaxis: {
        labels: {
          show: true,
          formatter: (val) => Math.round(val), // Ensure integers
      },
    },
    series: [
      {
        data: yearData,
      },
    ],
  };

  const quarterChartOptions = {
    chart: {
      id: 'barQuarter',
      type: 'bar',
      height: 400,
      stacked: true,
    },
    plotOptions: {
      bar: {
        columnWidth: '50%',
        horizontal: false,
      },
    },
    legend: {
      show: false,
    },
    grid: {
      yaxis: {
        lines: {
          show: false,
        },
      },
      xaxis: {
        lines: {
          show: true,
        },
      },
    },
    yaxis: {
        labels: {
          show: true,
          formatter: (val) => Math.round(val), // Ensure integers
      },
    },
    xaxis: {
        title: {
          text: 'Month', // X-axis title
        },
    },
    title: {
      text: 'Number of Songs Released Per Month',
      offsetX: 10,
    },
    tooltip: {
      x: {
        formatter: (val, opts) => opts.w.globals.seriesNames[opts.seriesIndex],
      },
      y: {
        title: {
          formatter: (val, opts) => opts.w.globals.labels[opts.dataPointIndex],
        },
        formatter: (val) => Math.round(val) // Ensure integers in tooltip
      },
    },
    series: quarterData,
  };

  if (isLoading) return <div>Loading...</div>;
  if (error) return <div>Error: {error}</div>;

  return (
    <div id="wrap">
      <div className="chart-container">
        <div id="chart-year" className={`chart-box ${quarterData.length ? 'chart-quarter-activated' : ''}`}>
          <Chart options={yearChartOptions} series={yearChartOptions.series} type="bar" height={400} />
        </div>
        {quarterData.length > 0 && (
          <div id="chart-quarter" className={`chart-box ${quarterData.length ? 'active' : ''}`}>
            <Chart options={quarterChartOptions} series={quarterChartOptions.series} type="bar" height={400} />
          </div>
        )}
      </div>
    </div>
  );
};

export default PlaylistTimeChart;
