// LayoutWithSidebar.js
import React from 'react';
import './LayoutStyles.css'; 
import Sidebar from '../Components/Sidebar'; 

const LayoutWithSidebar = ({ children }) => {
    return (
        <div className="app-layout">
            <Sidebar />
            <div className="main-content">{children}</div>
        </div>
    );
};

export default LayoutWithSidebar;
