import React from 'react';
import { Container } from 'react-bootstrap';
import packageInfo from '../../package.json'; // Adjust the path as needed


function LandingPage() {
  
  const handleLogin = () => {
    // Fetch the authentication URL from the Flask backend
    fetch(`${packageInfo.proxy}/login`)
      .then((response) => response.text()) // Expecting a text response, not JSON
      .then((authUrl) => {
        // Redirect the user to the Spotify authentication URL
        window.location.href = authUrl; // Use href for redirection
      })
      .catch((error) => {
        console.error('Error fetching auth URL', error);
      });
  };

 
  
  
  return (
    <div>
      <Container style={{ marginTop: '50px' }}>
      <h1>Welcome to Our Spotify Analytics App</h1>
      <p style={{ fontSize: '1.2rem', margin: '25px 0' }}>
      This is a simple simple app that allows you to analyze your Spotify playlists.
      </p>
      <p style={{ fontSize: '1.2rem', margin: '25px 0' }}>
      To get started, please login with your Spotify account. 
      </p>
      <p style={{ fontSize: '1.2rem', margin: '25px 0' }}>
      Go to the Home page to view all your playlists once logged in. Click on one of your playlists to analyze and generate a custom image for it!
      </p>
      <button onClick={handleLogin}>Login with Spotify</button>
    </Container> 
    </div>
  );
}

export default LandingPage;
