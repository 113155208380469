// Sidebar.js
import React from 'react';
import { NavLink } from 'react-router-dom';
import usePlaylistsData from '../hooks/usePlaylistsData'; // Import the custom hook

const Sidebar = () => {
  const { playlists, isLoading } = usePlaylistsData();

  if (isLoading) {
    return <div>Loading playlists...</div>;
  }

  // Check if playlists is not null before calling map
  return (
    <div className="sidebar">
      <div className="sidebar-header">
        <h2>Playlists</h2>
      </div>
      <div className="playlist-links">
        {playlists && playlists.map((playlist, index) => ( // Check for null or undefined
          <NavLink key={index} to={`/playlist/${playlist.id}`} className="playlist-link">
            {playlist.name}
          </NavLink>
        ))}
      </div>
    </div>
  );
};

export default Sidebar;
