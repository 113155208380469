import React from 'react';
// import { useState, useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';

import { Container, Row, Col } from 'react-bootstrap';
import CustomNavbar from './Components/Navbar';
import Sidebar_orginal from './Components/Sidebar';
//import ContentArea from './Components/ContentArea';
import LandingPage from './Components/LandingPage'; // The landing page component
// import ConnectPage from './Components/ConnectPage'; // The Connect page component
// import PlaylistPage from './Components/PlaylistPage'; // The Playlist page component
import PlaylistDetailPage from './Components/PlaylistDetailPage'; // The Playlist page component
import Test from './Components/Test'; 
// import TestPlaylist from './Components/TestPlaylist'; 
import FetchPlaylists from './Components/FetchPlaylists';
import Sidebar from './Components/Sidebar'; 




function App() {
  return (
    <Router>
      <div className="App">
        <CustomNavbar />
        <Routes>
          <Route path="/" element={<LandingPage />} />
          <Route path="/connect" element={<LandingPage />} />
          <Route path="/test" element={<Test />} />
          <Route path="/playlist" element={<FetchPlaylists />} />
          <Route path="/playlist/:playlistId" element={<PlaylistDetailPage />} />
        </Routes>
      </div>
    </Router>
  );
}

export default App;
