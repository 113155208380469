// components/TreemapChart.js
import React from 'react';
import ReactApexChart from 'react-apexcharts';

const TreemapChart = ({ options, series }) => {
    return (
        <div className="treemap-chart-container">
            <ReactApexChart options={options} series={series} type="treemap" height={350} />
        </div>
    );
};

export default TreemapChart;
