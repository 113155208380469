import { useState, useEffect } from 'react';
import packageInfo from '../../package.json'; // Adjust the path as needed

const usePlaylistData = (playlistId) => {
    const [data, setData] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState('');

    useEffect(() => {
        setIsLoading(true);
        setError('');

        // Fetch playlist metrics
        fetch(`${packageInfo.proxy}/calculate_metrics/${playlistId}`, {
            method: 'GET',
            mode: 'cors',
            credentials: 'include',
        })
            .then(response => {
                if (!response.ok) {
                    throw new Error(`HTTP error! status: ${response.status}`);
                }
                return response.json();
            })
            .then(data => {
                // console.log('Fetched data:', data);  // Debugging statement
                setData(data);
                setIsLoading(false);
            })
            .catch(error => {
                // console.error('Error fetching playlist metrics:', error);
                setError('Failed to fetch playlist metrics.');
                setIsLoading(false);
            });

    }, [playlistId]);

    return { data, isLoading, error };
};

export default usePlaylistData;
