import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import LayoutWithSidebar from '../layouts/LayoutWithSidebar';
import RadarChart from './RadarChart';
import TreemapChart from './TreemapChart';
import ImageGenerator from './ImageGenerator';
import TrackListTable from './TrackListTable';
import PlaylistTimeChart from './PlaylistTimeChart';
import usePlaylistData from '../hooks/usePlaylistData';
import useChartData from '../hooks/useChartData';
import './PlaylistDetailStyles.css';

const PlaylistDetailPage = () => {
    const { playlistId } = useParams();
    const { data: playlistMetrics, isLoading, error } = usePlaylistData(playlistId);
    const { radarChartData, treemapChartData } = useChartData(playlistMetrics);

    useEffect(() => {
        
    }, [playlistMetrics]);

    if (isLoading) {
        return <div>Loading playlist details...</div>;
    }

    if (error) {
        return <div>Error: {error}</div>;
    }

    return (
        <LayoutWithSidebar>
            <div className="playlist-container">
                <h1 className="playlist-title">{playlistMetrics?.metrics?.playlist_name || 'Playlist'}</h1>

                <ImageGenerator playlistId={playlistId} />
                <RadarChart options={radarChartData.options} series={radarChartData.series} />
                <PlaylistTimeChart playlistId={playlistId} />

                <TreemapChart options={treemapChartData.options} series={treemapChartData.series} />

                {playlistMetrics?.tracks && <TrackListTable tracks={playlistMetrics.tracks} />}
            </div>
        </LayoutWithSidebar>
    );
};

export default PlaylistDetailPage;
